const sectionsList = [
  {
    'sectionName': '',
    'sectionDisplayName': 'Projects',
    // 'sectionBgImage': 'https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/airadventurelevel4.png?alt=media&token=1ec8f2a5-a716-4a7a-a7b0-39ca1f7da7b6',
    'navLink': true,
    'linkPath': "/",
  },{
    'sectionName': 'about',
    'sectionDisplayName': 'About',
    'navLink': true,
    'linkPath': "/about",
  }, {
    'sectionName': 'experience',
    'sectionDisplayName': 'Experience',
    'navLink': false,
    'linkPath': "/experience",
  }, {
    'sectionName': 'contact',
    'sectionDisplayName': 'Contact',
    'navLink': true,
    'linkPath': "/contact",
  }
];

export default sectionsList;