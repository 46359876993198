import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyBaL7jxp7jZKtI86mJEIhjLHEDA6MQ5Qis",
  authDomain: "shaigarusicom.firebaseapp.com",
  databaseURL: "https://shaigarusicom.firebaseio.com",
  projectId: "shaigarusicom",
  storageBucket: "shaigarusicom.appspot.com",
  messagingSenderId: "362850058152",
  appId: "1:362850058152:web:bde21ef2a4f5ce669b679d"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const db = firebase.firestore();
const fireTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, db, fireTimestamp };