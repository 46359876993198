import React from 'react';
import './FormMsg.scss';
import { motion } from 'framer-motion';


const FormMsg = ({ time, status }) => {
  return (
    <motion.div className={`form-message-wrapper ${status === 200 ? 'success' : 'error'}`} initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0, duration: 0.6, ease: "easeIn" }}>
      {status === 200 ? 
      <>
      <i className="material-icons-round md-36">mark_email_read</i>
      <p>Your message has been sent</p>
      <p className="redirect-time-left">Redirecting in {time}s</p>
      </>
      :
      <>
      <i className="material-icons-round md-36">report</i>
      <p>Error occurred, Please try again.</p>
      </>
      }

    </motion.div>
  )

}

export default FormMsg;