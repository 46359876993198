import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import sectionList from '../data/sectionsList';
import './Nav.scss';
// import Footer from './Footer';

const Nav = ({ setIsMenuOpen, isMenuOpen, currentPage, isPortrait }) => {

    const loadingBackVariants = {
        hidden: {
            width: isPortrait ? "100%" : "32%",
            opacity: 0,
            transition: {
                duration: 0.3,
                delay: 0
            },
        },
        visible: {
            width: isPortrait ? "100%" : "32%",
            // x: 0,
            opacity: 1,
            transition: {
                duration: 0.3,
                delay: 0
            },
        }
    };


    const loadingContainerVariants = {
        hidden: {
            opacity: 1,
        },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };
    const loadingItemVariants = {
        hidden: {
            x: isPortrait  ? 50 : -50,
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 0.3
            },
            
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 0.3
            },
        }
    }

    const linkClickedHande = () => {
        setIsMenuOpen(false);
    }

    return (
        <>
                <motion.div
                    className={`nav-wrapper${!isPortrait ? ' side-bar' : ''}`}
                    variants={loadingBackVariants}
                    exit={{x: isPortrait  ? 0 : "-132%", opacity: 0, transition:{delay: 0,duration: 0.6}}}
                    transition={{
                        delay: 0.3,
                        duration: 0.6,
                    }}
                    initial="hidden"
                    animate={`${isMenuOpen ? 'visible' : 'hidden'}`}>
                    <motion.ul
                        className="nav-inner-wrapper"
                    variants={loadingContainerVariants} initial="hidden" animate="visible" exit="hidden">
                        {sectionList.map((sectionLink, i) => {
                            if (sectionLink.navLink === true)
                                return <motion.li
                                    key={`navlink-${i}`}
                                    variants={loadingItemVariants}
                                    className={`nav-link ${sectionLink.sectionName === currentPage.substring(1) && 'selected'}`}>
                                    <Link id={sectionLink.sectionName} to={sectionLink.linkPath} replace onClick={linkClickedHande}>
                                        <h3 id={sectionLink.sectionName} className="nav-button text-btn">{sectionLink.sectionDisplayName}</h3>
                                    </Link>
                                </motion.li>
                            return null;
                        })}
                    </motion.ul>
                    {/* <Footer/> */}
                </motion.div>
        </>
    )
}
export default Nav;


