import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import clientsList from '../data/clientsList';
import './ItemFull.scss';

const ItemFull = ({ setSelectedItem, selectedItem, isPortrait,setItemOpen }) => {
  const [selectedImage, setSelectedImage] = useState(selectedItem.metadata.customMetadata.projectImages[0].url);
  const [selectedImageType, setSelectedImageType] = useState("image");

  let currentClient
  // let currentPosition
  const closeHandleClick = (e) => {
    // overlaybox Click to Exit
    if (e.target.classList.contains('overlaybox') && !e.target.classList.contains('modal-item-wrapper')) {
      setSelectedItem(null);
      setItemOpen(false)
    }
  }
  const buttonHandleClick = (e) => {
    setSelectedItem(null);
    setItemOpen(false)
  }

  const imageSelectionHandleClick = (e) => {
    if (!e.type) {
      let selectedImageType = "image";
      setSelectedImageType(selectedImageType);
    } else {
      let selectedImageType = e.type;
      setSelectedImageType(selectedImageType);
    }
    let selectedImage = e.url;
    setSelectedImage(selectedImage);
  }

  // Load Current Item Client Info
  if (selectedItem && selectedItem.metadata.customMetadata.projectClientName) {
    for (var i = 0;
      i < clientsList.length;
      i++) {
      if (clientsList[i].clientName === selectedItem.metadata.customMetadata.projectClientName) {
        currentClient = clientsList[i];
      }
    }
  }

  const infoVariants = {
    hidden: {
      // x: 50,
      opacity: 0,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.3,
      }

    },
    visible: {
      // x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.3,
      }
    },
    exit: {
      // x: -50,
      opacity: 0,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.3,
      }
    }
  };

  return (
    <motion.div className="overlaybox"
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={closeHandleClick}
      transition={{ delay: 0.3, duration: 0.3, }}>
      <motion.div className={`modal-item-wrapper ${isPortrait ? '' : 'horz'}`} id="itemwrapper"
      initial={{ y: "100%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: "100%", opacity: 0 }}
      transition={{ delay: 0.15, duration: 0.6 }}>
      <div className="modal-ctrl">
      <button className="close-btn" type="button" alt="Close" onClick={buttonHandleClick}>
            <i className="material-icons-round">close</i>
          </button>
      </div>
      
        <div className={`modal-image-wrapper ${selectedItem.metadata.customMetadata.projectImages.length > 1 ? 'showGallery' : ''}`}>
          {selectedImageType === "image" &&
            <>
              <AnimatePresence>
                <motion.div className="modal-image-background" style={{ backgroundImage: "url(" + selectedImage + ")" }}
                  key={`bg-${selectedImage}`}
                  initial={{ opacity: 1, scale: 2.5 }} animate={{ opacity: 0.5, scale: 2.5 }} exit={{ opacity: 1, scale: 2.5 }} transition={{ delay: 0, duration: 0.3 }} />
              </AnimatePresence>
              <AnimatePresence exitBeforeEnter>
                <motion.img
                  className="modal-image"
                  layoutId={selectedItem.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }} transition={{ delay: 0, duration: 0.3 }}
                  key={`image-${selectedImage}`}
                  src={selectedImage}
                  alt={selectedItem.metadata.customMetadata.projectName} />
              </AnimatePresence>
              <motion.div className="modal-image-top-layer" />
            </>}
          {selectedImageType === "video" && 
            <iframe className="modal-video-player" title="videoplayer" width="100%" height="100%" src={`https://www.youtube.com/embed/${selectedImage}?playlist=${selectedImage}&loop=1&controls=0&autoplay=1&showinfo=0&disablekb=1&modestbranding=1&playsinline=1`} frameBorder="0" allow="autoplay; encrypted-media; gyroscope; "></iframe>
          }
          {selectedItem && selectedItem.metadata.customMetadata.projectImages.length > 1 &&
            <motion.ul className="modal-additional-images"
              initial={{ y: !isPortrait ? 0 : 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0, duration: 0.3 }} >
              {selectedItem.metadata.customMetadata.projectImages.map((image, imnum) => (
                <motion.li className={`additional-image-wrapper${selectedImage === image.url ? ' selected' : ''}`}
                  key={`${image.url}-${imnum}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 0, duration: 0.6 }}
                  onClick={() => imageSelectionHandleClick(image)}>
                  {image.type === "image" && <img className="modal-additional-image" src={image.url} alt="" />}
                  {image.type === "video" &&
                    <div className="modal-additional-video" >
                      <img className="modal-additional-image" src={`https://img.youtube.com/vi/${image.url}/1.jpg`} alt="" />
                      <i className="material-icons-round md-24 play-icon">play_arrow</i>
                    </div>}
                  <div className="modal-image-top-layer" />
                </motion.li>
              ))}
            </motion.ul>}

        </div>
        <motion.div className="modal-info-wrapper">
          <AnimatePresence exitBeforeEnter>
            <motion.div className="modal-text-wrapper"
              key={`item-${selectedItem.id}`}
              variants={infoVariants} initial="hidden" animate="visible" exit="exit"
            >
              <div className="modal-headline-wrapper" >
                <h3 className="modal-text-name">
                  {selectedItem.metadata.customMetadata.projectName}
                </h3>
                <h3 className="modal-text-years" >
                  {selectedItem.projectYear}</h3>
              </div>
              <div className="modal-scroll-wrapper">

                <div className="modal-details-bar-wrapper" >
                  <img className="client-logo" src={currentClient.clientLogo} alt="" />
                  <div className="modal-client-wrapper">
                    <h4 className="modal-text-client-position" >
                      {currentClient.position}
                    </h4>
                    <h4 className="modal-text-client-name" alt={currentClient.clientInfo}>
                      {selectedItem.metadata.customMetadata.projectClientName}
                    </h4>
                    <span className="modal-text-client-info" >
                      {currentClient.clientInfo}
                    </span>
                  </div>
                </div>

                {selectedItem.metadata.customMetadata.projectInfo && selectedItem.metadata.customMetadata.projectInfo.map((line, ln) => (
                  <p key={`desc-${ln}`} className="modal-text-desc">{line}</p>
                ))}

                <div className="modal-tools-wrapper" >
                  {selectedItem && selectedItem.metadata.customMetadata.projectTools.map((tool, tn) => (
                    <span className="modal-tool sm-text" key={`tool-${tn}`}>
                      {tool}
                    </span>
                  ))}
                </div>

              </div>
            </motion.div>
          </AnimatePresence>
        </motion.div>
        <div className="close-text" onClick={buttonHandleClick}>
            <p>Close</p>
          </div>
        {/* <div className='item-buttons-wrapper'>
          <button className="close-btn" type="button" alt="Close" onClick={buttonHandleClick}>
            <i className="material-icons-round">close</i>
          </button>
        </div> */}

      </motion.div>
    </motion.div>
  )
}

export default ItemFull;
