const clientList = [
  {
    'visability': true,
    'clientName': 'Playgorithm',
    'position': 'Product UX/UI',
    'empYears': '2018-2019',
    'desc':["Worked as part of a small startup team alongside the company's founding members. We built a SaaS content gamification platform that provide publishers and brands with the power to create unique interactive content experiences with automated data driven solutions.","Conducted extensive user research and usability tests to constantly improve user experience, deliver new products, features and visual designs using wireframes, user flows, concept sketches and prototypes.", "Designed and developed all of Playgorithm’s products and services interfaces, including each interactive format, creation and management platform, website, blog and landing pages." ],
    'clientInfo': 'Interactive Content Experiences',
    'clientLogo': 'https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/logos%2Fcl-playgorithm.png?alt=media&token=5ab393d0-698e-4352-821e-9c950a3fa536',
  }, {
    'visability': true,
    'clientName': 'Habetzefer',
    'position': 'Art Director',
    'empYears': '2017-2018',
    'desc':["Worked as a part of the marketing team, collaborated with advisors and course managers to understand our potentional costumers requierments, identify barriers and marketing opportunities.","Created an engaging and effective designs and layouts used for marketing ads, landing pages and printed graphics, addressing each course separately while maintain and strengthen the overall brand.", "Managed the brand identity and positioning across social media platforms, produced new content on a daily basis by creating real time marketing ads, innovative social activities and sharing relevant news." ],
    'clientInfo': 'Advertising & Marketing Academy',
    'clientLogo': 'https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/logos%2Fcl-habetzefer.png?alt=media&token=f049de16-b03b-4f32-ae8d-cc64c91a63b8',
  }, {
    'visability': true,
    'clientName': 'Lidor Adv',
    'position': 'Graphic Designer',
    'empYears': '2016-2017',
    'desc':["Produced graphic designs used for advertising campaigns across magazines, newspapers, billboards, brochures and social platforms for our clients, following each brand identity and guidelines."],
    'clientInfo': 'Advertising & Media',
    'clientLogo': 'https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/logos%2Fcl-lidor.png?alt=media&token=9a725bfa-6b2f-4bd0-b2c3-daf4c72542f3',
  }, {
    'visability': false,
    'clientName': 'Private',
    'position': 'Self-Employeed',
    'empYears': '2010-2021',
    'clientInfo': 'Creative Services',
    'clientLogo': 'https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/logos%2Fcl-shaigarusi.png?alt=media&token=527653c5-e03b-4f9d-8c20-e21f55314bf6',
  }, {
    'visability': false,
    'clientName': 'Concept',
    'position': 'Self-Employeed',
    'empYears': '2010-2021',
    'clientInfo': 'Creative Services',
    'clientLogo': 'https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/logos%2Fcl-shaigarusi.png?alt=media&token=527653c5-e03b-4f9d-8c20-e21f55314bf6',
  }
];

export default clientList;