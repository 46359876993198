import React,{ useState } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Div100vh from 'react-div-100vh'
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import PageHeader from './containers/PageHeader';
// import Homepage from './comps/Homepage';
import About from './comps/About';
import ItemsHS from './comps/ItemsHS';
import Contact from './comps/Contact';
// import ClickerGame from './comps/ClickerGame';
// import UploadForm from './comps/UploadForm';
import ErrorPage from './comps/ErrorPage';
import Nav from './comps/Nav';


function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState(false);

  const location = useLocation();
  const currentPage = location.pathname;
  
  // MediaQuery
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 900 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  
  return (
    <>
      <Div100vh>
        <div className={`shaigarusiApp ${isMobileDevice ? 'mobile' : ''} ${isPortrait ? 'portrait' : ''}`}>
          <AnimateSharedLayout type="switch">
            <div className="App">
              <PageHeader currentPage={currentPage} isPortrait={isPortrait} isMobile={isMobileDevice} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} itemOpen={itemOpen}/>
              <AnimatePresence exitBeforeEnter>
            {isMenuOpen && 
          <Nav isPortrait={isPortrait} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} currentPage={currentPage} />
        }
          </AnimatePresence>
              <div className={`main-wrapper${isMenuOpen && !isPortrait ? ' ma-on' : ''}`}>
                <Switch>
                  {/* <Route exact path="/" component={Homepage} /> */}
                  <Route path="/about" component={About} />
                  {/* <Route path="/clicker" component={ClickerGame} /> */}
                  {/* <Route exact path="/uploadform" component={UploadForm} /> */}
                  <Route
                    path='/contact'
                    render={(props) => (
                      <Contact {...props} isMobile={isMobileDevice} />
                    )}
                  />


                  <Route
                    exact path='/'
                    render={(props) => (
                      <ItemsHS {...props} isPortrait={isPortrait} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} itemOpen={itemOpen} setItemOpen={setItemOpen}/>
                    )}
                  />
                  <Route path='*' component={ErrorPage} />
                </Switch>
              </div>
              
              {isMenuOpen && !isPortrait && <>
              <div className="pers-shadow"/>
              <div className="pers-shadow shadow-two"/>
              </>}
            </div>

          </AnimateSharedLayout>
        </div>
      </Div100vh>
    </>
  );
}

export default App;
