const ButtonVariants = {
    hidden: {
        opacity: 0,
        scale:0.9,
        transition: {
            type: "tween",
            ease: "easeOut",
            duration: 0.1
        },
        
    },
    visible: {
        opacity: 1,
        scale:1,
        transition: {
            type: "tween",
            ease: "easeOut",
            duration: 0.1
        },
    },
    tapped: {
        opacity: 1,
        scale: 0.9,
        transition: {
            type: "tween",
            ease: "easeOut",
            duration: 0.1
        },
    }
};

const loadingContainerVariants = {
    hidden: {
        transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1,
        },
    },
        visible: {
        transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1,
        },
    },
};
const loadingItemVariants = {
    hidden: {
        y: "100vh",
        transition: {
            type: "tween",
            ease: "easeOut",
            duration: 0.6,
        },
    },
    visible: {
        y: 0,
        transition: {
            type: "tween",
            ease: "easeIn",
            duration: 0.6,
        },
    }
};
const labelFocusVariants = {
        hidden: {
            opacity: 0,
            transition: {
                type: "spring",
                ease: "easeOut",
                bounce: 0.4,
                stiffness: 100,
                duration: 0.6
            },

        },
        visible: {
            opacity: 1,
            transition: {
                type: "spring",
                ease: "easeIn",
                bounce: 0.4,
                stiffness: 100,
                duration: 0.6
            },
        }
    };
  
    const PageTransition = {
        out: {
            y: -100,
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeOut",
                duration: 0.6
            },

        },
        in: {
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 0.6
            },
        }
    };



  export {ButtonVariants, loadingContainerVariants,loadingItemVariants, labelFocusVariants, PageTransition};