import React from 'react';
import './Title.scss';
import { motion } from 'framer-motion';

const Title = ({ currentPage }) => {
  const aboutPage = currentPage.substring(1) === "about"
  
  return (
    <>
      <motion.div className="title-wrapper"
        initial={{ y: aboutPage ? 50 : 50, x: aboutPage ? 50 : 0, opacity: 0 }}
        animate={{ x: 0, y: 0, opacity: 1 }}
        exit={{ y: 50, x: 50, opacity: 0, transition: { delay: 0, duration: 0.6 } }}
        transition={{ delay: 0, duration: 0.6 }}>
        <h3 id="name" className="main-title" >Shai Garusi</h3>
        <span id="desc" className="desc sm-text">Product Designer-Developer</span>
      </motion.div>
    </>
  )
}

export default Title;