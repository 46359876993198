

import React from 'react';
import './MenuButton.scss';
import { motion } from 'framer-motion';
import { ButtonVariants } from './Animations';


const MenuButton = ({setIsMenuOpen, isMenuOpen}) => {

  const menuButtonVariants = {
    hidden: {
    x : 0,
    scale:0,
      opacity: 0,
      transition: {
          type: "tween",
          ease: !isMenuOpen ? "easeOut" : "easeIn",
          duration: 0.6,
          delay: 0
      }
  },
  visOpen: {
    x : 10,
    scale:1,
    opacity: 1,
    transition: {
        type: "tween",
        ease: "easeIn",
        duration: 0.3,
        delay: 0
    }
},
    visible: {
      x : 0,
    scale:1,
        opacity: 1,
        transition: {
            type: "tween",
            ease: "easeIn",
            duration: 0.3,
            delay: 0
        },
    }
};
  return (
    <motion.span variants={menuButtonVariants}
    initial="hidden" animate={!isMenuOpen ? "visible" : "visOpen"}>
<motion.button id="menuButton"
        className={`menu-button secondary-btn}`}
        variants={ButtonVariants} initial="hidden" animate="visible" whileTap="tapped"
        
         type="button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <i className="material-icons-round">{!isMenuOpen ? "menu" : "menu_open"}</i>
        </motion.button>
    </motion.span>
  )
}

export default MenuButton;