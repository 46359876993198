import React from 'react';
import Title from '../comps/Title';
import MenuButton from '../comps/MenuButton';
import './PageHeader.scss';
import { motion, AnimatePresence } from 'framer-motion';


const PageHeader = ({ currentPage, isMenuOpen, setIsMenuOpen, itemOpen }) => {

  return (
    <>
      <header className={`page-header${isMenuOpen ? ' mopen': ''} ${currentPage.substring(1)}`}>
              <AnimatePresence>
      {!itemOpen &&
            <motion.div className="top-navbar" initial={{ y: -100, opacity:0 }} exit={{ y: -100, opacity:0 }} animate={{ y: 0, opacity:1 }} transition={{ delay: 0, duration: 0.6 }}>
            <div className="nav-left-wrapper">
              {/* <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/> */}
    
              <AnimatePresence>
                {
                // currentPage.length > 1 &&
                currentPage.substring(1) !== "about" | isMenuOpen &&
                  <Title currentPage={currentPage}/>
                }
              </AnimatePresence>
    
            </div>
            <div className="nav-right-wrapper">
              {/* <div className="top-nav-link">
                <DownloadCV />
              </div> */}
              <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
    {/* 
              <Link className="top-nav-link" to="/about" replace>
                <TopRightButton />
              </Link> */}
            </div>
          </motion.div>
    
      }
              </AnimatePresence>

    </header>
</>
  )
}

export default PageHeader;