import React,{useState} from 'react';
import './About.scss';
import { motion } from 'framer-motion';
import { ButtonVariants, PageTransition } from './Animations';
import { openInNewTab } from './NewTabLink';
import { Redirect } from 'react-router';

const About = () => {
  const [buttonClick, setButtonClick] = useState(false);
  const [redirectActive, setRedirectActive] = useState(false);

  const aboutPageContent = {
    headline: "Hi, I'm Shai Garusi",
    headlineContact: "Contact Now",
    text: ["Designer & Developer with years of experience in product and marketing positions, extensive technical skills in a variety of design, development, and marketing tools. Specializes in creating tailor-made prototypes, visual concepts, digital products, and marketing assets, ideation to production."],
    // statusText: "Available",
  }

  const buttonHandler = () => {
    setButtonClick(true);
    setTimeout(() => {
      setRedirectActive(true)
    }, 300);

  }

  if (redirectActive) {
      return <Redirect to="/contact" />
}

  return (
    <motion.div className="about-wrapper" variants={PageTransition} initial="out" animate={buttonClick ? "out" : "in"} exit="out">
      <div className="about-content-wrapper">
        <h2 className="headline">{aboutPageContent.headline}</h2>
        {aboutPageContent.text.map((text, ln) => (
          <span key={`at-${ln}`} className="about-text">
            {text}
          </span>
          
        )
        )}
        {/* <span className="status-text">
          <div className="dot-blink" /> {aboutPageContent.statusText}
        </span> */}
        <div className="buttons-wrapper">
          <div className="contact-link">
            <motion.button className="linkedin-btn s-btn"
              variants={ButtonVariants} initial="hidden" animate="visible" whileTap="tapped"
              type="button" onClick={() => openInNewTab('https://www.linkedin.com/in/shaigarusi/')}>
              <img className="linkedin-icon"
                src="https://firebasestorage.googleapis.com/v0/b/shaigarusicom.appspot.com/o/linkedin-logo-white.png?alt=media&token=67f49594-0af8-4bca-ac25-a48d4d3b4117"
                alt="Linkedin Profile" />
            </motion.button>
          </div>
          <div className="contact-link" onClick={buttonHandler}>
            <motion.button
              className="primary-btn m-btn glow-btn"
              type="button"
              variants={ButtonVariants} initial="hidden" animate="visible" whileTap="tapped">
              Let's Talk
            </motion.button>
            </div>
        </div>
      </div>
    </motion.div>
  )
}

export default About;