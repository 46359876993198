import React, { useState, useRef } from 'react';
import useFirestore from '../hooks/useFirestore';
import { motion, AnimatePresence } from 'framer-motion';
import ItemPPT from './ItemPPT';
import './ItemsHS.scss';
import { loadingContainerVariants, loadingItemVariants, ButtonVariants, PageTransition } from './Animations';
import ItemFull from './ItemFull';
import { Redirect } from 'react-router';

var executed = false;

const ItemsHS = ({ isPortrait, isMenuOpen, setIsMenuOpen, itemOpen, setItemOpen }) => {
    const { docs } = useFirestore('projects');
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemsOpened, setItemsOpened] = useState([]);
    const [itemsOver, setItemsOver] = useState(false);
    const [popItem, setPopItem] = useState(false);

    const [buttonClick, setButtonClick] = useState(false);
    const [redirectActive, setRedirectActive] = useState(false);

    const setItem = (doc) => {
        // Add Opened Items
        if (!itemsOpened.includes(doc.id)) {
            itemsOpened.push(doc.id);
            setItemsOpened(itemsOpened);
        }

        // Set Item
        let selectedItem = doc;
        setSelectedItem(selectedItem);
        setItemOpen(true)

        // Close Menu if it's Open
        if (isMenuOpen) {
            setIsMenuOpen(!isMenuOpen)
        }

        // All items viewed
        if (itemsOpened.length === docs.length) {
            setItemsOver(true)
            setPopItem(true)
        }
        // console.log("selectedItem ", selectedItem)
    }

    // Scroll to Object if Items Over
    const ref = useRef(null)
    if (ref.current !== null && itemsOver) {
        const scrollTo = ref.current
        scrollTo.scrollIntoView({
            behavior: "smooth",
        });
    }


    // Scroll Pop

    const handleScroll = (e) => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const scrollYPos = scrollHeight - scrollTop - clientHeight;
        if (scrollYPos <= 400) {
            setPopItem(true)
        }
    }

    const scrlRef = useRef(null)

    if (popItem && !executed) {
        setTimeout(function () {
            const scrolObj = document.getElementById('scrlObj');
            scrolObj.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
            executed = true;
        }, 1000);
    }

    const buttonHandler = () => {
        setButtonClick(true);
        setTimeout(() => {
            setRedirectActive(true)
        }, 300);

    }

    if (redirectActive) {
        return <Redirect to="/contact" />
    }

    return (
        <>
            <motion.div className="items-grid-wrapper" variants={PageTransition} initial="out" animate={buttonClick ? "out" : "in"}>
                <motion.ul
                    className="vertical" onScroll={handleScroll}
                    // className={`hs ${isMobile && isPortrait ? 'vertical' : ''}`}
                    variants={loadingContainerVariants} initial="hidden" animate="visible">
                    <AnimatePresence>
                        {docs && docs.map((doc, dn) => {
                            if (doc.visibility === true)
                                return <motion.li
                                    key={`id-${doc.id}-${dn}`}
                                    className={`item-wrapper ${itemsOpened && itemsOpened.includes(doc.id) ? 'opened' : ''}`}
                                    variants={loadingItemVariants}
                                    initial="hidden" animate="visible" exit="hidden"
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => setItem(doc)}>
                                    <div className="item-image-wrapper">
                                        <img className="item-image" src={doc.url}
                                            // layoutId={doc.id}
                                            alt={`Project - ${doc.metadata.customMetadata.projectName}`} />
                                    </div>
                                    <div className="item-caption">
                                        <h3>{doc.metadata.customMetadata.projectName}</h3>
                                        <span>{doc.metadata.customMetadata.projectClientName}</span>
                                    </div>
                                </motion.li>
                            return null
                        })}
                        {itemsOver && <motion.span key="itemsOver" ref={ref} initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0, duration: 0.3, }} className="list-end">
                            You've watched all available projects
                                        </motion.span>}
                        {!!popItem &&
                            <motion.li id="scrlObj" key="popItem" ref={scrlRef} className="item-wrapper pop-item perspectiveAnim"
                                initial={{ y: 100, scale: 0, opacity: 0 }}
                                animate={{ y: 0, scale: 1, opacity: 1 }}
                                transition={{ delay: 0, duration: 0.6, }}
                                // whileTap={{ scale: 0.95 }}
                                onClick={() => {
                                }}>
                                <div className="item-caption">
                                    <h3>Contact me to find out what </h3>
                                    <h3>we can create together</h3>
                                </div>
                                <div className="btn-wrapper">
                                <motion.button
                                        className="primary-btn glow-btn bob-anim m-btn"
                                        type="button" variants={ButtonVariants}
                                        initial="hidden" animate="visible" whileTap="tapped" onClick={buttonHandler}>
                                        Let's Talk
                                    </motion.button>
                                </div>
                            </motion.li>}
                    </AnimatePresence>
                </motion.ul>

                <AnimatePresence>
                    {selectedItem && selectedItem.itemLayout === "full" &&
                        <ItemFull selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            setItemOpen={setItemOpen}
                            itemsArray={docs}
                            itemsOpened={itemsOpened}
                            setItemsOpened={setItemsOpened}
                            isPortrait={isPortrait} />
                    }
                    {selectedItem && selectedItem.itemLayout === "ppt" &&
                        <ItemPPT selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            setItemOpen={setItemOpen}
                            itemsArray={docs}
                            itemsOpened={itemsOpened}
                            setItemsOpened={setItemsOpened}
                            isPortrait={isPortrait} />
                    }
                </AnimatePresence>
            </motion.div>

            <div className="spacer" />
        </>
    )
}

export default ItemsHS;

