import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.scss';

const ErrorPage = () => {
    return (
        <>
            <div className="error-page-wrapper">
                <div className="error-msg">
                <h2>404</h2>
                <p>The link is broken or the page has been removed. Try these pages instead:</p>
                </div>
                <div className="buttons-wrapper">
                    <Link to="/">
                    Homepage
                    </Link>
                    <Link to="/about">
                    About
                    </Link>
                    <Link to="/projects">
                    Projects
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ErrorPage;