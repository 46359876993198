import React, { useState, useEffect } from 'react';
import './Contact.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { labelFocusVariants, loadingContainerVariants, ButtonVariants, PageTransition } from './Animations';
import FormMsg from './FormMsg';
import emailjs from 'emailjs-com';
import { Redirect } from 'react-router';

const Contact = ({ isMobile }) => {
    const [nameLabelFocus, setNameLabelFocus] = useState(false);
    const [emailLabelFocus, setEmailLabelFocus] = useState(false);
    const [msgLabelFocus, setMsgLabelFocus] = useState(false);

    const [nameValidate, setNameValidate] = useState(false);
    const [emailValidate, setEmailValidate] = useState(false);
    const [msgValidate, setMsgValidate] = useState(false);

    const [formValidate, setFormValidate] = useState(false);
    const [sendBtn, setSendBtn] = useState("Send");
    const [errorMsg, setErrorMsg] = useState("");

    const [formSent, setFormSent] = useState(false);
    const [formMsg, setFormMsg] = useState(0);

    const [copyEmail, setCopyEmail] = useState(false);

    const [redirectActive, setRedirectActive] = useState(false);
    const [timeToRedirect, setTimeToRedirect] = useState(5);

    if (!formValidate && nameValidate && emailValidate && msgValidate) {
        setFormValidate(true)
    }

    // Copy to Clipboard
    function copyToClipboard(textToCopy) {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(textToCopy)
            setCopyEmail(true);
            setTimeout(() => {
                setCopyEmail(false)
            }, 1800);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopyEmail(true);
                setTimeout(() => {
                    setCopyEmail(false)
                }, 1800);
            });
        }

    }

    // Validation
    const nameErorrs = {
        'short': "Please enter your name",
        'long': "Name is too long",
        'charts': "Name must contain only letters and spaces",
    }

    const mailErrors = {
        'short': "Please enter email address",
        'long': "Email address is too long",
        'charts': "Invalid email address",
    }
    const msgErrors = {
        'short': "Please enter message",
        'long': "Message too long",
    }

    const InputError = () => {
        return (
            <motion.span
                className="sm-text input-val-t"
                variants={labelFocusVariants}
                initial="hidden"
                animate="visible">
                {errorMsg}
            </motion.span>
        )
    }

    // Name Validation
    function ValidateName(e) {
        var letters = /^[A-Za-z\s]+$/

        // Check if empty
        if (nameLabelFocus && e.target.value.length === 0) {
            setNameValidate(false);
            setFormValidate(false)
            setErrorMsg(nameErorrs.short)
            return;
        } else {
            if (e.target.value.length <= 30) {
                // Vaidates for a-z
                if (e.target.value.match(letters)) {
                    setErrorMsg("");
                    setNameValidate(true);
                    return;
                } else {
                    setErrorMsg(nameErorrs.charts);
                    setNameValidate(false);
                    setFormValidate(false)
                    return;
                }
            } else {
                setNameValidate(false);
                setFormValidate(false)
                setErrorMsg(nameErorrs.long);
                return;
            }
        }
    }

    // User Email Validation
    function ValidateEmail(e) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

        // Check if empty
        if (emailLabelFocus && e.target.value.length === 0) {
            setEmailValidate(false);
            setFormValidate(false);
            setErrorMsg(mailErrors.short);
            return;
        } else {
            if (e.target.value.length <= 30) {
                if (e.target.value.match(mailformat)) {
                    setErrorMsg("");
                    setEmailValidate(true);
                    return;
                } else {
                    setEmailValidate(false);
                    setFormValidate(false);
                    setErrorMsg(mailErrors.charts);
                    return;
                }
            } else {
                setEmailValidate(false);
                setFormValidate(false);
                setErrorMsg(mailErrors.long);
                return;
            }
        }
    }

    // Message Validation
    function ValidateMsg(e) {

        // Check if empty
        if (setMsgLabelFocus && e.target.value.length === 0) {
            setMsgValidate(false);
            setFormValidate(false);
            setErrorMsg(msgErrors.short);
            return;
        } else {
            if (e.target.value.length <= 600 && e.target.value.length > 0) {
                setErrorMsg("");
                setMsgValidate(true);
                return;
            } else {
                setMsgValidate(false);
                setFormValidate(false);
                setErrorMsg(msgErrors.long);
                return;
            }
        }
    }


    // OnFocus Live - Form Validation
    const InputFocusChange = (e) => {
        e.preventDefault();
        // Name Validation
        if (e.target.name === "name") {
            ValidateName(e)
            // Set input focus
            setEmailLabelFocus(false)
            setMsgLabelFocus(false)
            setNameLabelFocus(true)
        }
        // User Email Validation
        if (e.target.name === "user_email") {
            // Set input focus
            setNameLabelFocus(false)
            setMsgLabelFocus(false)
            setEmailLabelFocus(true)
            ValidateEmail(e)
        }
        // Message Validation
        if (e.target.name === "message") {
            // Set input focus
            setNameLabelFocus(false)
            setEmailLabelFocus(false)
            setMsgLabelFocus(true)
            ValidateMsg(e)
        }
    }


    // Validation User Focus
    function setInputFocus() {
        setErrorMsg("");
        setEmailLabelFocus(true)
        setMsgLabelFocus(true)
        setNameLabelFocus(true)
    }

    // Handle Form Send
    const sendFormHandle = (e) => {
        e.preventDefault();
        if (nameValidate && emailValidate && msgValidate) {
            setSendBtn("Sending...")
            sendEmail(e)
            return;
        } else {
            if (!nameValidate) {
                setInputFocus()
                return;
            }
            if (!emailValidate) {
                setInputFocus()
                return;
            }
            if (!msgValidate) {
                setInputFocus()
                return;
            } else return;
        }
    }


    // Send Email
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm(
            'service_8ytdauv',
            'template_7bshlfp',
            e.target,
            'user_oUjG753zC9gvV1fYZmEm0'
        ).then(res => {
            setSendBtn("Sent");
            setFormSent(true);
            let formMsg = res.status
            setFormMsg(formMsg);
            setTimeout(() => {
                setRedirectActive(true)
            }, (autoRedirectDelay * 1000));
        })
            .catch(err => {
                setSendBtn("Send");
                console.log(err)
                setFormMsg(err.status);
                setFormSent(true);
                setTimeout(() => {
                    setFormSent(false);
                }, (autoRedirectDelay * 1000));
            })


        setTimeout(() => {
            if (formMsg === 0) {
                setFormMsg(10);
                setFormSent(true);
                setSendBtn("Send")
            }
        }, 60000);

    }

    let autoRedirectDelay = timeToRedirect + 1;

    useEffect(() => {
        if (formSent && timeToRedirect > 0) {
            setTimeout(() => setTimeToRedirect(timeToRedirect - 1), 1000);
        } else return;
    });

    if (redirectActive) {
        return <Redirect to="/" />
    }

    return (

        // <div className="contact-wrapper">
        //             <FormMsg time={timeToRedirect} status={200}/>
        // </div>

        <motion.div className="contact-wrapper" variants={PageTransition} initial="out" animate="in" exit="out">
            {formSent && formMsg === 200 ?
                <FormMsg time={timeToRedirect} status={formMsg} />
                :
                <>
                    <motion.form className="contact-form" variants={loadingContainerVariants} animate="visible" initial="hidden" exit="hidden"
                        onSubmit={sendFormHandle}>
                        <motion.h2 variants={labelFocusVariants} initial="hidden" animate="visible" exit="hidden"
                            className="headline">Let's Talk</motion.h2>
                        <motion.div className="to-email-address"
                            variants={labelFocusVariants} initial="hidden" animate="visible" exit="hidden">
                            <span className="to">To:</span>
                            <span className="to-address">mail@shaigarusi.com</span>
                            <motion.i className="copy-i material-icons-round md-18" alt="Copy to clipboard" onClick={() => copyToClipboard("mail@shaigarusi.com")}
                                variants={ButtonVariants} initial="hidden" animate="visible" whileTap="tapped">copy</motion.i>
                            <AnimatePresence>
                                {copyEmail && <motion.span className="copy-success sm-text"
                                    initial={{ y: 0, x: -50, scale: 0, opacity: 0 }}
                                    animate={{ y: "-20px", x: "-16px", scale: 1, opacity: 1 }}
                                    exit={{ y: 0, x: -50, scale: 0, opacity: 0 }}
                                    transition={{ type: "tween", ease: "easeOut", duration: 0.3 }}>Copied!</motion.span>}
                            </AnimatePresence>
                        </motion.div>
                        <motion.div 
                        // className={`input-wrapper${isMobile ? '' : ' split'}`}
                        className="input-wrapper"
                        variants={labelFocusVariants} initial="hidden" animate="visible" exit="hidden">
                            <motion.label className="form-label" variants={labelFocusVariants} initial="hidden" animate="visible">
                                Name
                        {nameLabelFocus && !nameValidate &&
                                    <i className="form-val-i material-icons-round md-18" >
                                        {nameValidate ? "check_circle" : "error"}
                                    </i>}
                            </motion.label>

                            <motion.input className="form-input"
                                type="text" name="name" placeholder="Name" onChange={InputFocusChange}
                                variants={labelFocusVariants} initial="hidden" animate="visible" />
                            {nameLabelFocus && errorMsg && errorMsg.includes(nameErorrs.short) | errorMsg.includes(nameErorrs.long) | errorMsg.includes(nameErorrs.charts) &&
                                <InputError />}
                        </motion.div>
                        <motion.div className="input-wrapper" variants={labelFocusVariants} initial="hidden" animate="visible" exit="hidden">
                            <motion.label className="form-label" variants={labelFocusVariants} initial="hidden" animate="visible">
                                Email
                            {emailLabelFocus && !emailValidate &&
                                    <i className="form-val-i material-icons-round md-18" >
                                        {emailValidate ? "check_circle" : "error"}
                                    </i>}
                            </motion.label>

                            <motion.input className="form-input"
                                type="email" name="user_email" placeholder="Email address" onChange={InputFocusChange}
                                variants={labelFocusVariants} initial="hidden" animate="visible" />
                            {emailLabelFocus && errorMsg && errorMsg.includes(mailErrors.short) | errorMsg.includes(mailErrors.long) | errorMsg.includes(mailErrors.charts) &&
                                <InputError />}
                        </motion.div>
                        <motion.div className="input-wrapper" variants={labelFocusVariants} initial="hidden" animate="visible" exit="hidden">
                            <motion.label className="form-label" variants={labelFocusVariants} initial="hidden" animate="visible">
                            Message
                            {msgLabelFocus && !msgValidate &&
                                    <i className="form-val-i material-icons-round md-18" >
                                        {msgValidate ? "check_circle" : "error"}
                                    </i>}
                            </motion.label>

                            <motion.textarea className="form-input"
                                name="message" rows={isMobile ? '4' : '8'} placeholder="Message" onChange={InputFocusChange}
                                variants={labelFocusVariants} initial="hidden" animate="visible" />
                            {msgLabelFocus && errorMsg && errorMsg.includes(msgErrors.short) | errorMsg.includes(msgErrors.long) &&
                                <InputError />}
                        </motion.div>
                        
                        <motion.button className={`send-btn m-btn primary-btn ${!formValidate ? ' disabled' : 'glow-btn'}`} type="submit" value="Send"
                            variants={labelFocusVariants} initial="hidden" animate="visible" exit="hidden">{sendBtn}</motion.button>
                            
                    </motion.form>
                    {formSent && formMsg < 200 && formMsg > 0 &&
                        <FormMsg time={timeToRedirect} status={formMsg} />
                    }
                </>
            }
        </motion.div>

    );
}

export default Contact;
