import { useState, useEffect } from 'react';
import { db } from '../firebase/config';

const useFirestore = (collection) => {
  const [docs, setDocs] = useState([]);

  // useEffect(() => {
  //   const unsub = db.collection(collection)
  //     .orderBy('projorder', 'asc')
  //     .onSnapshot(snap => {
  //       let documents = [];
  //       snap.forEach(doc => {
  //         documents.push({...doc.data(), id: doc.id});
  //       });
  //       setDocs(documents);
  //     });

  //   return () => unsub();
  //   // this is a cleanup function that react will run when
  //   // a component using the hook unmounts
  // }, [collection]);

  useEffect(() => {
    const unsub = db.collection(collection)
    .where("visibility", "==", true)
    .orderBy('projorder', 'asc')
    .onSnapshot(snap => {
      let documents = [];
      snap.forEach(doc => {
        // if (doc.data().visibility) {
          documents.push({...doc.data(), id: doc.id});
          // console.log("visibility: ",doc.id)
        // } else {
        //   console.log("Not Visible: ", doc.id)
        // }
      });
      setDocs(documents);
    });

    return () => unsub();
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
  }, [collection]);



  return { docs };
}

export default useFirestore;