import React from 'react';
import { motion } from 'framer-motion';
import './ItemPPT.scss';

const ItemPPT = ({ setSelectedItem, selectedItem, isPortrait,setItemOpen }) => {

  // let currentPosition
  const closeHandleClick = (e) => {
    // overlaybox Click to Exit
    if (e.target.classList.contains('overlaybox') && !e.target.classList.contains('modal-item-wrapper')) {
      setSelectedItem(null);
      setItemOpen(false)
    }
  }
  const buttonHandleClick = (e) => {
    setSelectedItem(null);
    setItemOpen(false)

  }



  return (
    <motion.div className="overlaybox"
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={closeHandleClick}
      transition={{ delay: 0.3, duration: 0.3, }}>
      <motion.div className={`modal-ppt-wrapper ${isPortrait ? '' : 'horz'}`} 
      initial={{ y: "100%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: "100%", opacity: 0 }}
      transition={{ delay: 0.15, duration: 0.6 }}>
      <div className="modal-ctrl">
          <button className="close-btn" type="button" alt="Close" onClick={buttonHandleClick}>
            <i className="material-icons-round">close</i>
          </button>
        </div>
        {selectedItem.metadata.customMetadata.projectImages.map((image, imnum) => (
          <div key={`${image.url}-${imnum}`} className="modal-image-ppt-wrapper">
            <img className="modal-ppt-image" src={image.url} alt="" />
            <div className="modal-image-top-layer" />
          </div>
        ))}
              <div className="close-text" onClick={buttonHandleClick}>
            <p>Close</p>
          </div>
      </motion.div>
    </motion.div>
  )
}

export default ItemPPT;


